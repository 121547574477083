body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
}

.App.waiting {
  background-color: rgb(255, 255, 82);
}

.App.yes {
  background-color: rgb(28, 167, 28);
}

.App.no {
  background-color: rgb(255, 151, 151);
}

a,
h1,
h3 {
  text-align: center;
  display: block;
  width: 100%;
}

a:visited {
  color: inherit;
}
